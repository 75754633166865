/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';

const TopClientsRow = ({ client }) => {

  return (
    <tr className="top_client_row"
      onClick={() => AffiniPay.DashboardUtils.searchForClient(client.name)} >
      <td>{ client.name || client.email }</td>
      <td className="text-right numeric">${ AffiniPay.DashboardUtils.numberFormat(client.totalVolume / 100, 2) }</td>
      <td className="numeric">{ client.numberOfTransactions }</td>
    </tr>
  );
};

export default TopClientsRow;

TopClientsRow.propTypes = {
  client: PropTypes.object
};
